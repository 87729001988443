@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

$theme-colors: (
  primary: #05dbf2,
  primary-light: #cafbff,
  primary-dark: #24a9c7,
  secondary: #d7d7d9,
  info: rgb(0, 58, 173),
  primary-flat: rgba(0, 58, 173, 0.1),
  selected: rgb(218, 215, 215),
  primary-flat-2: #0367a6,
  danger: #bb2d3a,
  muted: #d7d7d9,
  success: #28a745,
  light: #f8f9fa,
  warning: #ffca2b,
  dark: #023453,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";
@import "react-big-calendar/lib/sass/styles";
@import "react-big-calendar/lib/addons/dragAndDrop/styles";

html,
body,
#root {
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  font-family: "PT Sans", sans-serif;
}

.filter-button {
  position: sticky;
  top: 5px;
  left: -5px;
  z-index: 99999;
}

.drive-file-uploader {
  border: 2px dotted lightgray;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.left-bar {
  border-left: 10px solid #000;
}

.base-table-custom:hover {
  background-color: white !important;
}

.input-font {
  font-size: 12px !important;
}

.input-label {
  font-size: 14px;
}

.sidebar {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
  display: flex;
  font-family: "Anton", sans-serif;
  justify-content: center;
  align-items: center;
}

$shooting-time: 3000ms;

.night {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateZ(45deg);
  // animation: sky 200000ms linear infinite;
}

.shooting_star {
  position: absolute;
  left: 50%;
  top: 50%;
  // width: 100px;
  height: 2px;
  background: linear-gradient(
    -45deg,
    rgba(95, 145, 255, 1),
    rgba(0, 0, 255, 0)
  );
  border-radius: 999px;
  filter: drop-shadow(0 0 6px rgba(105, 155, 255, 1));
  animation: tail $shooting-time ease-in-out infinite,
    shooting $shooting-time ease-in-out infinite;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    // width: 30px;
    height: 2px;
    background: linear-gradient(
      -45deg,
      rgba(0, 0, 255, 0),
      rgba(95, 145, 255, 1),
      rgba(0, 0, 255, 0)
    );
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    animation: shining $shooting-time ease-in-out infinite;
  }

  &::after {
    // CodePen Error
    // @extend .shooting_star::before;

    content: "";
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    // width: 30px;
    height: 2px;
    background: linear-gradient(
      -45deg,
      rgba(0, 0, 255, 0),
      rgba(95, 145, 255, 1),
      rgba(0, 0, 255, 0)
    );
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    animation: shining $shooting-time ease-in-out infinite;
    transform: translateX(50%) rotateZ(-45deg);
  }

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      $delay: random(9999) + 0ms;
      top: calc(50% - #{random(400) - 200px});
      left: calc(50% - #{random(300) + 0px});
      animation-delay: $delay;
      // opacity: random(50) / 100 + 0.5;

      &::before,
      &::after {
        animation-delay: $delay;
      }
    }
  }
}

@keyframes tail {
  0% {
    width: 0;
  }

  30% {
    width: 100px;
  }

  100% {
    width: 0;
  }
}

@keyframes shining {
  0% {
    width: 0;
  }

  50% {
    width: 30px;
  }

  100% {
    width: 0;
  }
}

@keyframes shooting {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
  }
}

@keyframes sky {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(45 + 360deg);
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin: 0rem;
  padding: 0rem;
}

.fade-in {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hover-light:hover {
  background-color: map-get($map: $theme-colors, $key: "primary-flat");
  cursor: pointer;
}

.hover-dark:hover {
  background-color: map-get($map: $theme-colors, $key: "warning");
  cursor: pointer;
}

.hover-info:hover {
  cursor: pointer !important;
  background-color: rgb(41, 70, 133);
}

.form-control:disabled {
  background: rgb(250, 250, 250);
}

.table thead th {
  vertical-align: middle;
  text-align: center;
}

.progressCircle {
  border-radius: 100%;
  border-width: 4px;
  border-style: double;
  border-color: map-get($map: $theme-colors, $key: info);
  background-color: map-get($map: $theme-colors, $key: primary-flat-2);
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  display: flex;
}

.progressCircle > div {
  font-size: 10px;
  text-align: center;
  margin: auto;
  color: map-get($map: $theme-colors, $key: dark);
}

.right-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #343a40;
  right: -8px;

  top: 10px;
}

.tinner-text {
  font-size: 8px !important;
}

.tiny-text {
  font-size: 10px !important;
}

.small-text {
  font-size: 12px !important;
}

.mid-text {
  font-size: 13px;
}

.large-text {
  font-size: 14px;
}

.xlarge-text {
  font-size: 15px;
}

.hover {
  cursor: pointer;
}

.form-generator-sticky-submit {
  position: fixed !important;
  bottom: 2px;
  right: 12px;
}

.pointer {
  cursor: pointer;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  padding: 0 !important;
}

.fixed-button {
  position: fixed;
  bottom: -10px;
  right: 10px;
}

.required-star {
  color: red;
  font-size: 15px;
}

.rbc-calendar {
  .rbc-toolbar {
    font-size: 12px;
    .rbc-toolbar-label {
      font-weight: 700;
      font-size: large;
    }
    & button {
      color: black;
      background-color: none;
      border: none;
      margin-right: 5px;
      margin-left: 5px;
      border: solid 1px gray !important;

      &:active,
      &.rbc-active {
        color: white;
        background-color: #05dbf2;

        &:hover,
        &:focus {
          color: white;
          background-color: #05dbf2;
        }
      }

      &:focus {
        color: white;
        background-color: #05dbf2;
      }

      &:hover {
        color: white;
        background-color: #05dbf2;
      }
    }
  }
  .rbc-btn-group {
    display: inline-block;
    white-space: nowrap;

    > button:first-child:not(:last-child) {
      border-radius: 5px;
    }

    > button:last-child:not(:first-child) {
      border-radius: 5px;
    }

    .rbc-rtl & > button:first-child:not(:last-child) {
      border-radius: 5px;
    }

    .rbc-rtl & > button:last-child:not(:first-child) {
      border-radius: 5px;
    }

    > button:not(:first-child):not(:last-child) {
      border-radius: 5px;
    }
  }
}

.rbc-event-content {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.075);
}
.rbc-show-more {
  color: gray !important;
  font-weight: 400;
}
.rbc-show-more:hover {
  color: black !important;
}

.rbc-day-slot .rbc-event-label {
  display: none;
}

.rbc-toolbar {
  font-size: 11px;
}

.rbc-day-slot .rbc-event {
  overflow: visible;
}

.rbc-time-gutter .rbc-timeslot-group .rbc-time-slot {
  border-right: 0px;
}
